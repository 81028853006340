<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Добавить график в договор № {{ item.vccarriercontractsDocnum }}
        </v-toolbar-title>
        <v-spacer/>

        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="onClose"
          >
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="pa-4">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Graphics
              :items="graphics"
              :loadingConfirm="loadingConfirm"
              :loadingRefs="loadingRefs"
              :routes="routes"
              :sheets="sheets"
              @onCancel="cancelAdd"
              @onConfirm="confirmAdd"
              @onAdd="showAllGraphics"
              @onRemove="removeGraphic"
            ></Graphics>
          </v-tab-item>

          <v-tab-item>
            <AllGraphics
              :items="allGraphics"
              :loading="loadingAll"
              :loadingRefs="loadingRefs"
              :graphics="graphics"
              @onCancel="cancelAdd"
              @onAdd="onAdd"
            ></AllGraphics>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import Graphics from "./components/Graphics";
  import AllGraphics from "./components/AllGraphics";
  import GeneralServices from '@/services/GeneralServices';

  export default {
    name: "AddGraphic",
    components: {
      AllGraphics,
      Graphics
    },
    props: {
      collection: {
        type: Object,
        required: true,
      }
    },
    data: () => ({
      dateWork: null,
      dialog: true,
      item: null,
      loadingConfirm: false,
      loadingAll: false,
      loadingRefs: false,
      tab: 0,
      graphics: [],
      allGraphics: [],
      routes: [],
      sheets: [],
      contracts: [],
      certificates: [],
      startContractDt: null,
      endContractDt: null,
    }),
    created() {
      this.item = this.collection.master.selected;

      this.dateWork = $utils.formatDate(
        new Date(Date.now()),
        'YYYY-MM-DD HH:mm:ss',
      );
      this.startContractDt = this.item.vccarriercontractsStartcontractdt;
      this.endContractDt = this.item.vccarriercontractsEndcontractdt;
      this.contractId = this.item.vccarriercontractsId;

      this.loadingRefs = true;

      //Получение списка маршрутов и списка расписаний
      this.getLists();

      this.loadingRefs = false;
    },
    methods: {
      onClose() {
        this.dialog = !this.dialog;
      },
      cancelAdd() {
        this.tab = 0;
      },
      showAllGraphics() {
        this.tab = 1;
      },
      onAdd(items) {
        this.tab = 0;
        items.forEach(item => {
          const index = this.graphics.findIndex(graphic => {
            return graphic.id === item.id;
          });

          if (index === -1) {
            this.graphics.push(item);
          }
        });
      },
      removeGraphic(item) {
        this.graphics = this.graphics.filter(graphic => graphic.id !== item.id)
      },
      confirmAdd() {
        this.loadingConfirm = true;
        this.addGraphic(this.graphics);

      },
      async getGraphics(releaseScheduleId, startContractDt) {
        const startDtMillis = $utils.formatDate(
          new Date(startContractDt),
          'YYYY-MM-DD HH:mm:ss',
        );

        const requestGraphics = {
          type: 'core-read',
          query: `sin2:/v:fb018b08-10cb-4d46-8ea9-9cebcccd2d69?filter=and(eq(field(".versionId"),param("${releaseScheduleId}","id")),or(isnull(field(".endDt")),gte(field(".endDt"),param("${startDtMillis}","date")))`,
        };

        try {
          const resp = await jet.http.post(requestGraphics);
          if (!resp.error) {
            return resp.result;
          }
        } catch (e) {
          jet.msg({
            text: 'Не удалось загрузить список графиков',
            color: 'warning'
          });
          console.error('AddGraphic: getGraphics()', e);
          return;
        }

      },
      async getRoutesByContracts(carrierContractId, dateWork) {
        const requestContracts = {
          type: 'core-read',
          query: `sin2:/v:ffd7d02e-81e4-48bf-8066-c384c9385a84?filter=and(isnull(field(".vehicleId")),
                    eq(field(".contractId"),param("${carrierContractId}","id")),
                    lte(field(".routeId.route.verStart"),param("${dateWork}","date")),or(isnull(field(".routeId.route.verEnd")),
                    gte(field(".routeId.route.verEnd"),param("${dateWork}","date"))),
                    not(eq(field(".routeId.route.verStatus.Name"),param("Проект","string"))),or(isnull(field(".scheduleId")),
                    and(lte(field(".scheduleId.versionId.verStart"),param("${dateWork}","date")),
                    or(isnull(field(".scheduleId.versionId.verEnd")),
                    gte(field(".scheduleId.versionId.verEnd"),param("${dateWork}","date"))),
                    not(eq(field(".scheduleId.versionId.verStatus.Name"),param("Проект","string"))))))`
        }

        try {
          const resp = await jet.http.post(requestContracts);
          if (!resp.error) {
            return resp.result;
          }
        } catch (e) {
          jet.msg({
            text: 'Не удалось загрузить список маршрутов',
            color: 'warning'
          });
          console.error('AddGraphic: getRoutesByContracts()',e);
          return;
        }
      },
      async getLists() {
        this.loadingRefs = true;
        
        try {
          const routesByContracts = await this.getRoutesByContracts(this.contractId, this.dateWork);
          let routes = [];
          let sheets = [];
          let releaseScheduleId = '';
          let tripCode = '';
          let routeName = '';
          let routeId = '';
          let note = '';
          let version = '';
          let verstatus = '';
          let scheduleId = '';
          let graphics = [];

          const data = (routesByContracts && routesByContracts.data) || [];
          await Promise.all(data.map(async it => {
            releaseScheduleId = it[23];
            tripCode = it[12];
            routeName = it[3] + ' ' + it[4];
            routeId = it[19];
            note = it[15];
            scheduleId = it[20];
            verstatus = it[11];
            version = it[10];
        
            routes.push({
              id: routeId,
              name: routeName,
            });
            
            if(!!releaseScheduleId){
              sheets.push({
                id: releaseScheduleId,
                name: version,
                type: verstatus,
                routeId: routeId,
                routeName: routeName,
              });
              
            }
          }));

          this.routes = routes;
          this.sheets = $utils.uniqBy(sheets, sheet => sheet.id);
 
          await Promise.all(this.sheets.map(async it => {
            this.getGraphicList(it.id, it.routeId, it.routeName);
          }));

        } catch (e) {
          jet.msg({
            text: 'Не удалось загрузить список графиков',
            color: 'warning'
          });
          console.error('AddGraphic: getLists()', e);
          return;
        }
        this.loadingRefs = false;
      },
      async getGraphicList(releaseScheduleId, routeId, routeName){
        const graphicsList = await this.getGraphics(releaseScheduleId, this.startContractDt);
        const data = (graphicsList && graphicsList.data) || [];
        await Promise.all(data.map(async it => {
          this.allGraphics.push({
            id: it[2],
            startDate: GeneralServices.formatDate(this.startContractDt),
            endDate: GeneralServices.formatDate(this.endContractDt),
            contractId: this.contractId,
            routeId: routeId,
            releaseScheduleId: releaseScheduleId,
            description: null,
            code: it[1],
            routeName: routeName,
            scheduleId: it[2],
          });
        }));
      },
      async checkContracts(scheduleId, startContractDt, endContractDt) {
        const checkQuery = {
          type: 'core-read',
          query: `sin2:/v:e27d521f-e415-4172-bbf8-3445541bf7bd?filter=and(eq(field(".scheduleId"),param("${scheduleId}","id")),lte(field(".startContractDt"),param("${endContractDt}","date")),gte(field(".endContractDt"),param("${startContractDt}","date")))`
        };

        try {
          const resp = await jet.http.post(checkQuery);
          if (!resp.error) {
            return resp.result;
          }
        } catch (e) {
          jet.msg({
            text: 'Не удалось загрузить список договоров на дату',
            color: 'warning'
          });
          console.error('AddGraphic: checkContracts()',e);
          return;
        }
      },
      async checkCertificates(scheduleId, startContractDt, endContractDt) {
        const checkQuery = {
          type: 'core-read',
          query: `sin2:/v:605c8a85-c59b-49ef-99bd-2581c15a6902?filter=and(eq(field(".scheduleId"),param("${scheduleId}","id")),lte(field(".certificateId.startDt"),param("${endContractDt}","date")),gte(field(".certificateId.endDt"),param("${startContractDt}","date")))`
        };

        try {
          const resp = await jet.http.post(checkQuery);
          if (!resp.error) {
            return resp.result;
          }
        } catch (e) {
          jet.msg({
            text: 'Не удалось загрузить список серификатов на дату',
            color: 'warning'
          });
          console.error('AddGraphic: checkCertificates()',e);
          return;
        }
      },
      async addGraphic(paramsArray) {
        let params = [];
        let contracts = [];
        let certificates = [];
        let code = '';
        let docNum = '';
        let scheduleId = '';
        let releaseScheduleId = '';
        let id = '';
        
        for (let i = 0; i < paramsArray.length; i++) {
          code = paramsArray[i].code;
          scheduleId = paramsArray[i].scheduleId;

          const checkContracts = await this.checkContracts(scheduleId, this.startContractDt, this.endContractDt);

          const checkCertificates = await this.checkCertificates(scheduleId, this.startContractDt, this.endContractDt);

          if (checkContracts.data.length) {
            if (contracts.indexOf(checkContracts.data[i][3]) === -1) {
              contracts.push(checkContracts.data[i][3]);
            }
          }

          if (checkCertificates.data.length) {
            if (certificates.indexOf(`${checkCertificates.data[i][3]} ${checkCertificates.data[i][2]}`) === -1) {
              certificates.push(`${checkCertificates.data[i][3]} ${checkCertificates.data[i][2]}`);
            }
          }

          if (contracts.length) {
            jet.msg({
              text: `График  ${code} уже присутствует в договоре № ${contracts.join(', ')}.`,
              color: 'warning'
            });
            this.loadingConfirm = false;
            return;
          }

          if (certificates.length) {
            jet.msg({
              text: `График  ${code} уже присутствует в свидетельстве № ${certificates.join(', ')}.`,
              color: 'warning'
            });
            this.loadingConfirm = false;
            return;
          }

          id = jet.utils.uuidv4();
          params.push(
            {
              id: id,
              startcontractdt: this.startContractDt,
              endcontractdt: this.endContractDt,
              contractid: paramsArray[i].contractId,
              routeid: paramsArray[i].routeId,
              scheduleid: paramsArray[i].scheduleId
            }
          );
        }
        
        let {id: userId, tenant: tenantId} = this.$store.state.profile;
        const optionsQuery = {
          type: "query",
          query:"d16cb8ba-8ffd-4753-89bc-5c7499bfa500.addgraphicintocontract",
          params: {
            tenantid: tenantId, //this.item._sec_attrsTenantid,
            userid: userId,     //this.item._sec_attrsUserid,
            paramslist: JSON.stringify(params),
          }
        };
        try {
          let resp = await jet.http.post(optionsQuery);
          if (resp.error) {
            throw resp.error;
          }
          jet.msg({
            text: `Добавление графиков в договор № ${this.item.vccarriercontractsDocnum} выполнено успешно!`,
            type: 'warning'
          });
        } catch (e) {
          jet.msg({
            text: 'Не удалось добавить графики',
            color: 'warning'
          });
          console.error('AddGraphic: addGraphic()', e);
          return;
        }

        this.dialog = false;
        this.collection.refresh();
      }
    }
  }
</script>
